<template>
  <div>
    <v-container style="padding-top: 20px;min-height: 700px !important;">
      <div :style="$vuetify.breakpoint.mobile ?  'width: 100%; margin: 0 auto' : 'width: 70%; margin: 0 auto'"> 
        <v-toolbar dense flat style="margin: 10px 0 10px 0; background-color: transparent;">
          <v-text-field
          v-model="searchByYourPurpose"
          :placeholder="$t('searchYourPurposeHere')"
          rounded
          solo
          append-icon="mdi-magnify"
          @click:append="handleSearch()"
          v-shortkey="['enter']"
          @shortkey.native="handleSearch()"
          :dense="$vuetify.breakpoint.mobile"
          ></v-text-field>
        </v-toolbar>
      </div>
      <div id="base-home">
        <div v-if="loading"
             style="position: fixed;display: flex;justify-content: center;align-items: center;top:0;left: 0;right: 0;bottom: 0; z-index: 200">
          <v-progress-circular style="text-align: center; "
                               :size="70"
                               :width="7"
                               color="purple"
                               indeterminate
          ></v-progress-circular>
        </div>
        <div class="search-content affix" v-scroll="onScroll" v-if="!$vuetify.breakpoint.mobile"
             v-show="toolbarSearch">
          <v-row style="padding-left: 30px;padding-right: 30px">
            <v-col cols="12" sm="1" md="1" style="padding-right: 0px !important;padding-left: 0px !important;">
              <v-btn-toggle
                  class="animate__animated animate__flipInX animate__duration-5s"
                  color="deep-purple accent-3"
                  rounded
                  dense
                  v-model="query.groupType"
                  style="font-size: 11px"
              >
                <v-btn value="sale" style="min-width: 60px !important;">
                  {{ $t('sale') }}
                </v-btn>
                <v-btn value="rent" style="min-width: 60px !important;">
                  {{ $t('rent') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                  v-model="query.countryId"
                  :items="countryList"
                  item-text="name"
                  item-value="number"
                  :placeholder="$t('country')"
                  persistent-hint
                  dense
                  solo
                  prepend-inner-icon="mdi-map-marker"
                  single-line
                  rounded
              >

              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                  v-model="query.locationId"
                  :items="locationOptionList"
                  item-text="label"
                  item-value="value"
                  :placeholder="$t('searchYourLocation')"
                  persistent-hint
                  dense
                  solo
                  prepend-inner-icon="mdi-map-marker"
                  single-line
                  rounded
                  clearable
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                  v-model="query.type"
                  :items="typeOption"
                  item-text="label"
                  item-value="value"
                  :placeholder="$t('allType')"
                  persistent-hint
                  dense
                  solo
                  clearable
                  prepend-inner-icon="mdi-shape"
                  single-line
                  rounded
              >
                <template v-slot:selection="{ item }">
                  {{ $t(item.label) }}
                </template>
                <template v-slot:item="{ item }">
                  {{ $t(item.label) }}
                </template>

              </v-select>
            </v-col>
            <v-col cols="6" sm="1" md="1" style="padding-right: 0px !important;padding-left: 0px !important;">
              <v-select
                  v-model="query.minPrice"
                  :items="minPriceOption"
                  :placeholder="$t('minPrice')"
                  persistent-hint
                  clearable
                  append-icon="mdi-currency-usd"
                  single-line
                  rounded
                  dense
                  solo
              >
                <template v-slot:selection="{ item }">
                  {{ item | separateNumber }}
                </template>
                <template v-slot:item="{ item }">
                  ${{ item | separateNumber }}
                </template>

              </v-select>
            </v-col>
            <v-col cols="6" sm="1" md="1" style="padding-right: 0px !important;padding-left: 0px !important;">
              <v-select
                  v-model="query.maxPrice"
                  :items="maxPriceOption"
                  :placeholder="$t('maxPrice')"
                  persistent-hint
                  clearable
                  append-icon="mdi-currency-usd"
                  single-line
                  rounded
                  dense
                  solo
              >
                <template v-slot:selection="{ item }">
                  {{ item | separateNumber }}
                </template>
                <template v-slot:item="{ item }">
                  ${{ item | separateNumber }}
                </template>

              </v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                  :placeholder="$t('searchYourPurposeHere')"
                  rounded
                  v-model="searchByYourPurpose"
                  solo
                  dense
                  append-icon="mdi-magnify"
                  class="searchInHomeMini"
                  @click:append="handleSearch()"
              ></v-text-field>

            </v-col>
          </v-row>
        </div>
        <!-- Filter search --->
        <v-row>
          <v-col cols="12" lg="2" style="padding-bottom: 0px !important;padding-top: 0px !important;">
            <v-select
                v-model="query.countryId"
                :items="countryList"
                item-text="name"
                item-value="number"
                :placeholder="$t('country')"
                persistent-hint
                outlined
                prepend-inner-icon="mdi-map-marker"
                single-line
                rounded
                :dense="$vuetify.breakpoint.mobile"
            >
            </v-select>
          </v-col>
          <v-col cols="12" lg="3" style="padding-bottom: 0px !important;padding-top: 0px !important;">
            <v-select
                v-model="query.locationId"
                :items="locationOptionList"
                item-text="label"
                item-value="value"
                :placeholder="$t('searchYourLocation')"
                persistent-hint
                prepend-inner-icon="mdi-map-marker"
                single-line
                outlined
                rounded
                clearable
                :dense="$vuetify.breakpoint.mobile"
            >

            </v-select>
          </v-col>
          <v-col cols="12" lg="3" style="padding-bottom: 0px !important;padding-top: 0px !important;">
            <v-select
                v-model="query.type"
                :items="typeOption"
                item-text="label"
                item-value="value"
                :placeholder="$t('allType')"
                persistent-hint
                clearable
                single-line
                rounded
                outlined
                :dense="$vuetify.breakpoint.mobile"
            >
              <template v-slot:selection="{ item }">
                {{ $t(item.label) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $t(item.label) }}
              </template>

            </v-select>
          </v-col>
          <v-col cols="6" lg="2" style="padding-bottom: 0px !important;padding-top: 0px !important;">
            <v-select
                v-model="query.minPrice"
                :items="minPriceOption"

                :placeholder="$t('minPrice')"
                persistent-hint
                clearable
                append-icon="mdi-currency-usd"
                single-line
                rounded
                outlined
                :dense="$vuetify.breakpoint.mobile"
            >
              <template v-slot:selection="{ item }">
                {{ item | separateNumber }}
              </template>
              <template v-slot:item="{ item }">
                ${{ item | separateNumber }}
              </template>

            </v-select>
          </v-col>
          <v-col cols="6" lg="2" style="padding-bottom: 0px !important;padding-top: 0px !important;">
            <v-select
                v-model="query.maxPrice"
                :items="maxPriceOption"
                :placeholder="$t('maxPrice')"
                persistent-hint
                clearable
                append-icon="mdi-currency-usd"
                single-line
                rounded
                outlined
                :dense="$vuetify.breakpoint.mobile"
            >
              <template v-slot:selection="{ item }">
                {{ item | separateNumber }}
              </template>
              <template v-slot:item="{ item }">
                ${{ item | separateNumber }}
              </template>

            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" style="text-align: right;padding: 0 12px 0 12px !important;">
            <v-btn-toggle
                class="animate__animated animate__flipInX animate__duration-5s"
                color="deep-purple accent-3"
                rounded
                v-model="query.groupType"
                style="font-size: 11px"
                :dense="$vuetify.breakpoint.mobile"
            >
              <v-btn value="sale" style="min-width: 150px !important;">
                {{ $t('sale') }}
              </v-btn>
              <v-btn value="rent" style="min-width: 150px !important;">
                {{ $t('rent') }}

              </v-btn>
            </v-btn-toggle>
          </v-col>
          
          <v-col cols="6" sm="4" md="3" v-for="d in propertyList" :key="d._id">
            <property-card :d="d"></property-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" style="text-align: center">
            <v-pagination
                v-model="page"
                :length="Math.ceil(totalPage/12)"
                :total-visible="9"
                circle
            ></v-pagination>
          </v-col>
        </v-row>

      </div>
    </v-container>
  </div>
</template>

<script>

import MainMixin from "../mixins/mainMixin";
import {countyMobileList} from "../libs/countryMobileWithFlag";
import _ from "lodash";
import propertyCard from '../components/propertyCard.vue';

export default {
  name: "Home",
  mounted() {
    this.$jQuery('body').off();
  },
  mixins: [MainMixin],
  components: {propertyCard},
  data() {
    return {
      eager: true,
      bottomValue: 1,
      active: true,
      closeOnContentClick: false,
      dense: this.$store.state.isDense,
      show: false,
      loading: false,
      page: 1,
      totalPage: 0,
      isIntersecting: false,
      isIntersectingBtnToggle: false,
      query: {
        locationId: "",
        type: "",
        search: "",
        minPrice: "",
        maxPrice: "",
        groupType: "sale",
        countryId: ""

      },
      searchByYourPurpose: "",
      toolbarSearch: false,

      searchType: "",
      sortBy: "",
      sortDesc: "",
      options: {},
      skip: 0,
      limit: 12,
      filter: "",

      minPriceOption: [],
      maxPriceOption: []
    }
  },
  beforeRouteUpdate(to, from, next) {
    let vm = this;
    if ( to.query.locationId !== from.query.locationId || to.query.type !== from.query.type || to.query.groupType !== from.query.groupType) {
      vm.$forceUpdate();
      // console.log("route updated")
      vm.query.locationId = to.query.locationId;
      vm.query.type = to.query.type;
      vm.query.groupType = to.query.groupType;
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    next();
  },
  methods: {
    goToDetail(link, query) {
      let vm = this;
      if (vm.$router.history.current.path !== link) {
        this.$router.push({
          name: link,
          query: query
        });
      }
    },
    handleSearch() {
      let vm = this;
      vm.queryProperty(vm.searchByYourPurpose, vm.skip, vm.limit);
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.toolbarSearch = top > 200;
      this.currentPoint = top;
    },
    queryProperty: _.debounce(function (val, skip, limit) {
      let vm = this;
      vm.fetchProperty({
        search: val || "",
        filter: this.filter || "",
        skip: skip || 0,
        limit: limit || 12,
        sortBy: vm.sortBy || "",
        sortDesc: vm.sortDesc || "",
        locationId: vm.query.locationId || "",
        type: vm.query.type || "",
        minPrice: vm.query.minPrice || "",
        maxPrice: vm.query.maxPrice || "",
        groupType: vm.query.groupType || "",
        status: "Free",
        isWeb: true,
        countryId: vm.query.countryId || "",
        favoriteUserId: localStorage.id || ""
      });
      vm.loading=false;
    }, 500)
    ,
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    }
    ,
    onIntersectBtnToggle(entries) {
      this.isIntersectingBtnToggle = entries[0].isIntersecting;
    }
  },
  watch: {
    "query.locationId"() {
      let vm = this;
      vm.queryProperty(vm.searchByYourPurpose);
      vm.page = 1;
      // vm.$router.replace({name:'sell-my-house-fast', query: {locationId: vm.query.locationId}})
    },
    "query.type"() {
      let vm = this;
      vm.queryProperty(vm.searchByYourPurpose);
      vm.page = 1;
      // vm.$router.replace({name:'sell-my-house-fast', query: {type: vm.query.type}})
    },
    "query.groupType"() {
      let vm = this;
      vm.queryProperty(vm.searchByYourPurpose);
      vm.page = 1;
      // vm.$router.replace({name:'sell-my-house-fast', query: {groupType: vm.query.groupType}})
    }
    ,
    "page"(val) {
      let vm = this;
      vm.loading = true;
      vm.skip = 12 * (val - 1);
      vm.limit = 12 + 12 * (val - 1);
      vm.queryProperty(this.searchByYourPurpose, vm.skip, vm.limit);
    },
    "query.minPrice"(val) {
      let vm = this;
      if (val && val !== "") {
        vm.maxPriceOption = [];
        vm.priceList.map((obj) => {
          if (obj > val) {
            vm.maxPriceOption.push(obj);
          }
        })
      } else {
        vm.maxPriceOption = vm.priceList;
      }
      this.queryProperty(this.searchByYourPurpose);
      vm.page = 1;

    },

    "query.maxPrice"(val) {
      let vm = this;
      if (val && val !== "") {
        vm.minPriceOption = [];
        vm.priceList.map((obj) => {
          if (obj < val) {
            vm.minPriceOption.push(obj);
          }
        })
      } else {
        vm.minPriceOption = vm.priceList;
      }
      this.queryProperty(this.searchByYourPurpose);
      vm.page = 1;

    },
    "query.countryId"(val) {
      let vm = this;
      vm.queryLocation(val);
      vm.queryProperty(this.searchByYourPurpose);
      vm.page = 1;
    },
  }
  ,
  created() {
    let vm = this;

    if (localStorage.country && localStorage.country !== "") {
      let cDoc = countyMobileList.find((o) => {
        return o.value === localStorage.country || o.number=== localStorage.country;
      });
      vm.query.countryId = cDoc.number;
      vm.$store.state.country = localStorage.country;
    } else {
      vm.$jQuery.get('https://www.cloudflare.com/cdn-cgi/trace', function (data) {
        if (data) {
          vm.$store.state.country = data.split("loc=")[1].substr(0, 2);
          let cDoc = countyMobileList.find((o) => {
            return o.value === vm.$store.state.country || o.number=== vm.$store.state.country;
          });
          localStorage.country = vm.$store.state.country;
          vm.query.countryId = cDoc.number;
        }
      })
    }

    if (vm.$route.query.countryId) {
      vm.query.countryId = vm.$route.query.countryId;
    }
    vm.query.locationId = vm.$route.query.locationId || "";
    vm.searchByYourPurpose = vm.$route.query.search || "";
    vm.query.groupType = vm.$route.query.groupType || "";
    vm.query.minPrice = vm.$route.query.minPrice || "";
    vm.query.maxPrice = vm.$route.query.maxPrice || "";
    vm.query.type = vm.$route.query.type || "";
    vm.queryProperty(vm.searchByYourPurpose);
    this.queryLocation();
    vm.minPriceOption = vm.priceList;
    vm.maxPriceOption = vm.priceList;

    const top = window.pageYOffset;
    this.currentPoint = top;
    let goPoint = this.currentPoint > 160 ? 135 : 0;
    this.$vuetify.goTo(goPoint);

  }
}
</script>